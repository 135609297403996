body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  overflow: visible;
  /* IE-only styles */
  -ms-scrollbar-base-color: #fff;
  -ms-scrollbar-arrow-color: #fff;
  -ms-scrollbar-face-color: #888;
}

input:-webkit-autofill:disabled {
  -webkit-text-fill-color: lightgray;
  -webkit-box-shadow: 0 0 0px 30px #f6f7fb inset;
  box-shadow: 0 0 0px 30px #f6f7fb inset;
  transition: background-color 5000s ease-in-out 0s;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: "transparent";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* The Kount iframe is positioned absolutely, so we need to override that so it does not cause our layout to overflow by 1px */
iframe#ibody {
  position: fixed !important;
}
